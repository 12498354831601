.cartFooter {
    background-color: var(--white);
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
}


.subTotalText {
    text-transform: capitalize;
    padding: 0;
 }

.subTotalBox {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.wrapper {
    padding: 24px 16px;
    border-top: 2px solid var(--gray);
}
