

.flipIcon {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}

.shippingIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

@keyframes shineAnimation {
  0% { left: -100%; }
  100% { left: 100%; }
}

.infoTextContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0.8em 0.5em 0.5em 0;
}

.infoText {
  font-family: Nimbus-Sans-Bold, sans-serif !important;
  font-weight: bold;
  background: linear-gradient(90deg, #4D9DF9 0%, #7C72EC 100%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s linear infinite;
}

.relativeInfoText {
  font-family: Nimbus-Sans-Bold, sans-serif !important;
  font-weight: bold;
  position: relative;
  background: linear-gradient(90deg, #4D9DF9 0%, #7C72EC 100%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s linear infinite;
}

.infoTextContainer::before,
.infoTextContainer::after {
  content: "";
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.6), transparent);
  animation: shineAnimation 3s linear infinite;
  transform: skewX(60deg);
}

.infoTextContainer::before {
  left: -100%;
}

.infoTextContainer::after {
  left: calc(-100% - 10px);
  animation-delay: 0.2s;
}

@keyframes fadeInOutSmooth {
  0% { opacity: 0; transform: scale(0.8); }      
  25% { opacity: 1; transform: scale(1); }    
  50% { opacity: 1; transform: scale(1); }   
  75% { opacity: 0; transform: scale(0.8); }  
  100% { opacity: 0; transform: scale(0.8); } 
}


.diamond1 {
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  bottom: -6px;
  right: -6px;
  background: linear-gradient(90deg, #89BFFF 0%, #7C74EC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInOutSmooth 3s infinite ease-in-out;
  animation-delay: 1.5s; /* Diamond1 starts after Diamond2 */
  opacity: 0; /* Ensure it's hidden at the start */
}

.diamond2 {
  font-weight: bold;
  font-size: 10px !important;
  position: absolute;
  top: 2px;
  right: 4px;
  background: linear-gradient(90deg, #89BFFF 0%, #7C74EC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInOutSmooth 3s infinite ease-in-out;
}

