.container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background: linear-gradient(90deg, rgba(255, 187, 158, 0.75) 0%, rgba(216, 186, 221, 0.75) 54.5%, rgba(181, 205, 248, 0.75) 98.5%);
}

.rewardBox {
    display: flex;
    align-items: center;
}

.rewardIcon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.container p {
    padding: 0;
    margin: 0;
}