.stepIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
}

.complete-first {
  /* width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 3px #91239163; */
}

.complete-second {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--purple-500);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 3px #91239163;
}

.complete-third {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--purple-500);
  background-color: var(--purple-500);
  display: flex;
  align-items: center;
  justify-content: center;
}
