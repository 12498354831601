
.cart {
    width: 100%;
    max-width: 100%;
    background-color: var(--white);
    position: relative;
}

.headerAndBody {
    max-width: 100%;
    overflow-y: auto;
    padding-bottom: 240px;
}

.cartItems {
    padding: 0px 16px 8px;
    margin-top: 24px;
}