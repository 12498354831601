@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shrinkOut {
  from {
    opacity: 1;
    height: 104px;
  }
  to {
    opacity: 0;
    height: 0;
  }
}

.rewardContainer {
  width: 100%;
  height: 104px;
  position: relative;
  animation: fadeIn 1s ease-in-out;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
}

.rewardContainer.hide {
  animation: shrinkOut 0.5s ease forwards;
}

.rewardBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #FFECC3 0%, #FFBB9E 48%, #D8BADD 97%);
}

.rewardContainer p {
  padding-bottom: 0;
  font-size: 1.375rem;
}


.closeIcon {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  color: var(--white);
  border: solid 1px var(--white);
  border-radius: 3px;
}
