.msg-container {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg-title {
  text-align: center;
  color: var(--orange);
  font-size: 1rem;
}
