/*Glymed Redsign Global Themes*/
:root {
  --dark-blue: #353954;
  --blue-400: #30415d;
  --blue: #5a789f;
  --light-blue: #89a8cb;
  --light-gray: #fbfbfb;
  --blue-500: #57c2f7;
  --blue-200: #e5ecf5;

  --gray: #f4f1f0;
  --dark-gray: #cdd6e6;
  --darker-gray: #efebe9;
  --gray-400: #c4bcb7;
  --gray-500: #938c87;
  --gray-600: #929292;
  --black: #1d1e1e;
  --turquoise: #527878;
  --light-turquoise: #799b9b;
  --purple-400: #896e94;
  --purple-500: #4c2a4c;
  --purple: #e8deec;
  --transparent-gray: #00000029;
  --transparent: rgba(0, 0, 0, 0);
  --transparent-black: rgba(0, 0, 0, 0.4);
  --transparent-white: rgba(255, 255, 255, 0.5);

  --white: white;
  --orange: #f5825c;
  --success: #66bb6a;
  --danger: #a6535f;

  --pro-tier: #39383a;
  --bronze-tier: #c17239;
  --silver-tier: #b9b4a9;
  --gold-tier: #e1b741;
  --platinum-tier: #8a8a8a;
  --diamond-tier: #8ecfe5;

  --os-orange: #ff6a13;
  --os-blue: #859fc1;
  --os-purple: #b29eba;
  --os-deep-blue: #315E8E;

  --ss-delievered: #51284F;
  --ss-other: #8D6E97
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Nimbus-Sans-Light;
  font-size: 1em;
  color: var(--black);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

p {
  line-height: 1.5;
  padding-bottom: 1rem;
  font-family: Nimbus-Sans-Light;
}

h1 {
  font-size: 1.75em;
  font-family: Presicav-Light;
  text-transform: uppercase;
  color: var(--black);
  padding-top: 0;
  padding-bottom: 1rem;
  line-height: 32px;
}
@media (min-width: 576px) {
  h1 {
    font-size: 2.125em;
    line-height: 38px;
  }
}

h2 {
  font-size: 1.5em;
  color: var(--black);
  font-family: Presicav-Light;
  text-transform: uppercase;
  line-height: 28px;
  padding-bottom: 1rem;
}

h3 {
  font-size: 1.125em;
  color: var(--black);
  font-family: Presicav-Light;
  text-transform: uppercase;
  line-height: 22px;
  padding-bottom: 1rem;
}

h4 {
  font-size: 1.125em;
  font-family: Nimbus-Sans-Regular;
  text-transform: uppercase;
  line-height: 22px;
  color: var(--black);
}

h5 {
  font-size: 1em;
  font-family: Nimbus-Sans-Regular;
  text-transform: uppercase;
  line-height: 20px;
  color: var(--black);
  padding-bottom: 1rem;
}

h6 {
  font-size: 1em;
  font-family: Nimbus-Sans-Regular;
  padding-bottom: 1rem;
  line-height: 20px;
}

button,
.button {
  height: 48px;
  /* min-width: 157px; */
  border: none;
  font-size: 1rem;
  font-family: Nimbus-Sans-Light;
  font-weight: 100;
  font-style: normal;
  transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  background-color: var(--gray);
  color: var(--black);
  outline: none;
  position: relative;
  user-select: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

button:focus,
.button:focus {
  outline: none;
  border: none;
}

button:hover,
.button:hover {
  background-color: "transparent";
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-ul {
  list-style-type: disc;
  padding-left: 3rem;
  margin-bottom: 1.3rem;
}
.theme-ul li {
  margin: 0.5rem 0;
}

/* button:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px var(--dark-gray);
}

button:active:after {
  box-shadow: 0 0 0 0 var(--dark-gray);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
} */

/* button:active {
  top: 1px;
} */

.link-in-btn {
  color: var(--black);
}
button:hover > .link-in-btn {
  color: var(--white);
}

.gy-btn {
  background-color: var(--blue);
  color: var(--gray);
}
.gy-btn:hover {
  background-color: var(--dark-gray);
  color: var(--black);
}

.gy-btn-primary {
  background-color: var(--dark-blue);
  color: var(--gray);
}
.gy-btn-primary:hover {
  background-color: var(--dark-gray);
  color: var(--black);
}

input,
.input {
  font-family: Nimbus-Sans-Light;
}

label,
.label {
  font-family: Nimbus-Sans-Regular;
}

a {
  font-family: Nimbus-Sans-Regular;
  text-decoration: none;
  user-select: none;
  color: var(--dark-blue);
}

/* a:link {
  color: var(--light-blue);

} */
/*
a:visited {
  color: inherit;
} */

/* TODO add theme */
/* a:focus {}
a:hover {
  color: var(--blue);
} */
/* a:active {} */

.window-scroll::-webkit-scrollbar {
  width: 0.4rem;
}

.window-scroll::-webkit-scrollbar-track {
  box-shadow: none;
}

.window-scroll::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray);
  outline: none;
  border-radius: 25px;
}
.gy-display h2 {
  font-size: 3em;
  padding: 1rem 0;
  color: var(--white);
}

.gy-display p {
  padding: 0;
}

.App {
  padding-top: 90px;
}

@media (min-width: 576px) {
  .gy-display h2 {
    font-size: 4.6875em;
  }
}

@media only screen and (max-width: 1200px) {
  .App {
    padding-top: 60px;
  }
}

.wrapper-center-display {
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-primary {
  color: var(--white);
  background-color: var(--blue);
  outline: 2px var(--blue) solid;
}

.button-secondary {
  background-color: var(--orange);
  color: var(--white);
  outline: 2px var(--orange) solid;
}
.button-secondary:hover {
  background-color: var(--dark-gray);
  color: var(--dark-blue);
}

.button-outline {
  background-color: transparent;
  color: var(--dark-blue);
  outline: 2px var(--dark-blue) solid;
}
.button-outline:hover {
  background-color: var(--dark-gray);
  color: var(--dark-blue);
  outline: none;
}

.button-fluid {
  width: 100%;
}

.loader {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.responsive-img {
  width: 100%;
  height: auto;
}

input {
  box-sizing: border-box;
}

.form-title {
  margin-bottom: 3.75rem;
}

.hidden {
  visibility: hidden;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.class-loading {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

hr {
  border: 1px solid var(--dark-gray);
}

.tab-card {
  /* margin-top: 3rem;
  padding: 1rem; */
  /* padding: 1em; */
  transition: 500ms;
}

.tab-card-subtitle {
  text-align: right;
  text-transform: uppercase;
  font-family: Nimbus-Sans-Light;
  text-transform: uppercase;
  font-weight: 200;
  color: var(--blue);
  padding: 0 0.2rem 1rem 1rem;
}

.text-center {
  text-align: center;
}

.tab-card:hover {
  -webkit-box-shadow: -2px 31px 31px -7px rgba(0, 0, 0, 0.18);
  box-shadow: -2px 31px 31px -7px rgba(0, 0, 0, 0.18);
  outline: 1px solid var(--gray);
  cursor: pointer;
}

.tab-card-title {
  color: var(--dark-blue);
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 1rem 1rem 1rem;
  height: 100%;
}

.calendar-tab-card-title {
  font-family: "Presicav-Light";
}

.tab-card-title-html p,
.tab-card-title-html h1,
.tab-card-title-html h2,
.tab-card-title-html h3,
.tab-card-title-html h4,
.tab-card-title-html h5,
.tab-card-title-html h6,
.tab-card-title-html a {
  padding-bottom: 0;
}

.tab-card-content {
  padding: 0 1rem 1rem 1rem;
}

.tab-card-html-content {
  overflow: hidden;
  max-height: 80px;
}

.tab-content-wrapper {
  padding: 1rem;
  margin: -1rem;
  background-color: var(--gray);
}

.tab-card-synopsis {
  height: 8rem;
  overflow: hidden;
}

.tab-card-opacity {
  height: 5rem;
  background: linear-gradient(
    0deg,
    rgba(244, 241, 240, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-top: -5rem;
  position: relative;
}

.tab-card-footer {
  padding: 1rem;
  /* margin: -1rem; */
  background-color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-content: center;
}

.tab-card-more {
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.9em;
  margin-top: 0.5rem;
  padding: 0;
  opacity: 0.7;
}

.tab-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h2 {
    color: var(--blue);
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.swiper-pagination-bullet-active {
  background-color: var(--dark-blue) !important ;
}

.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--dark-blue);
  font-size: 1.3em !important;
  margin-bottom: 3rem;
  opacity: 0.4;
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * Contentful content css
 * ----------------------------------------
 */

.contentful-content b {
  font-weight: bold;
}

.contentful-content i {
  font-style: italic;
}

.contentful-content ol {
  list-style-type: auto;
  list-style-position: inside;
}

.contentful-content ol li {
  padding-bottom: 1rem;
}

.contentful-content ol li p {
  display: inline;
}

.contentful-content p:empty {
  padding-bottom: 0;
}

.contentful-content .responsive-image,
.contentful-content .responsive-video {
  max-width: 100%;
  height: auto;
}

/**
 * ----------------------------------------
 * Breadcrumb detail global CSS
 * ----------------------------------------
 */

.breadcrumb-link-disable p,
.breadcrumb-link-disable a,
.breadcrumb-link-disable h1,
.breadcrumb-link-disable h2,
.breadcrumb-link-disable h3,
.breadcrumb-link-disable h4,
.breadcrumb-link-disable h5,
.breadcrumb-link-disable h6 {
  font-family: "Graphik";
  font-size: 1rem;
  font-weight: 200;
  color: var(--darkest-gray);
  cursor: unset;
  margin: 0;
  padding: 0;
}

/**
 * ----------------------------------------
 * swiper button global CSS
 * ----------------------------------------
 */

.swiper-button-prev,
.swiper-button-next {
  color: var(--white);
  background-color: var(--dark-blue);
  width: 32px !important;
  height: 32px !important;
  border-radius: 100%;
  opacity: 0.5;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
  margin-bottom: 0;
  opacity: 1;
  font-size: 1em !important;
  opacity: 0.7;
}

@media only screen and (max-width: 1024px) {
  .whyGlyMedSwiper .swiper-button-next {
    opacity: 0.2;
  }

  .whyGlyMedSwiper .swiper-button-prev {
    opacity: 0.2;
  }
}
