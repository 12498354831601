.gamification-main {
  display: flex;
  justify-content: center;
}

.gamification-main-shadow {
  display: flex;
  justify-content: center;
  box-shadow: 0px 10px 5px -5px var(--gray-400);
}

.MuiMobileStepper-progress {
  width: "100%";
  height: "10px";
  border-radius: "1rem";
  background: linear-gradient(
    90deg,
    rgba(184, 161, 201, 0.5) 0%,
    rgba(191, 202, 221, 0.5) 27%,
    rgba(181, 205, 248, 0.5) 53.5%,
    rgba(212, 188, 178, 0.5) 76.5%,
    rgba(254, 149, 101, 0.5) 97%
  );
}

.stepper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gamification-steps {
  display: flex;
  flex-direction: row;
  padding: 0.6rem 0.5rem 0.2rem 0.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.gamification-stepper {
  width: 100%;
}

.stepper-icon {
  padding: 0.6rem 0.5rem 0 0.5rem;
}

.status {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 0.5em;
}

.status-complete {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 0.5em;
  animation: slide-in 0.4s linear both;
}

@keyframes slide-in {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(40%);
  }
}

.task-complete {
  padding: unset;
}

.status:hover {
  cursor: pointer;
}

.icon-message {
  display: flex;
}

.cart-icon-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--purple);
  margin-right: 0.6em;
  border-radius: 4px;
}

.cart-icon {
  width: 18px;
  height: 18px;
}

.close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--purple);
  border-radius: 4px;
  padding: 4px;
}

.gift-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.7rem;
  background-color: var(--purple);
  margin-bottom: 0.6rem;
  margin: 0.5rem;
  cursor: pointer;
  padding: unset;
}

@media (max-width: 600px) {
  .gift-message {
    padding: 0.5rem;
  }
}
