.opt-in {
  display: flex;
  justify-content: center;
  background-color: var(--purple);
  height: 48px;
  width: 100%;
  padding: 8px 0px 8px 0px;
}

.opt-in-container {
  position: relative;
  display: flex;
  align-items: center;
}

.opt-in-main {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.opt-in-text {
  justify-content: center;
  padding-right: 20px;
  font-size: 18px !important;
  font-family: Nimbus-sans-regular;
  flex-wrap: wrap;
}

.sms-sign-up {
  text-transform: none;
  margin: 0 20px 0 20px;
  padding: 0 20px 0 20px;
  border-left: 0px;
  text-wrap: nowrap;
  font-size: 16px;
}

.sms-sign-up:hover {
  cursor: pointer;
}

.opt-in-close {
  position: absolute !important;
  right: 0;
  color: var(--black);
  margin-left: 10px;
}

@media (max-width: 600px) {
  .opt-in {
    height: 60px;
  }

  .opt-in-text {
    margin-right: 40px;
  }

  .sms-sign-up {
    border-left: 1px solid var(--black);
    margin: 0 10px 0 10px;
    padding: 0 10px 0 10px;
  }
}
