.gy-regen-reward-referral-body {
  width: 100%;
  min-height: 30em;
  margin-top: 2%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.gy-regen-reward-referral-body-content {
  display: grid;
  grid-gap: 2%;
  width: 60%;
}

.gy-regen-reward-referral-body-content-text {
  font-weight: 400;
  line-height: 1.5;
}

.gy-regen-reward-referral-body-heading {
  margin-bottom: 2%;
  width: 60%;
  text-align: center;
}

.gy-regen-reward-referral-body-heading h4 {
  color: #1d1e1e;
}

.gy-regen-reward-referral-body-button-text {
  background-color: #5a789f;
  color: white;
}

.gy-regen-reward-referral-body-button {
  margin-top: 3%;
  margin-bottom: 2%;
}

