.shipping-sub-title-main {
  color: var(--white);
  background: linear-gradient(
      0deg,
      rgba(53, 57, 84, 0.5),
      rgba(53, 57, 84, 0.5)
    ),
    linear-gradient(90deg, #89c1ff 0%, #7c72ec 99.81%);
}
.shipping-sub-title {
  padding: 0.6rem;
  color: #fff;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
}
