.cart-thumbnail {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  border: solid 0.5px var(--gray);
  box-sizing: border-box;
  /* max-height: 150px; */
}

.cart-thumbnail-info {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  padding: 8px 8px 8px 0;
}

.cart-thumbnail-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  background-color: var(--white);
}

.title {
  line-height: 1;
  padding-bottom: 26px;
}

.priceBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.priceText {
  color: var(--blue) !important;
  padding: 0;
  white-space: nowrap;
  text-align: right;
}

@media screen and (max-width: 900px) {
  .title {
    padding-bottom: 20px;
  }

  .priceBox {
    display: block;
  }
  
}
