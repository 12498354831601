.container {
  margin-bottom: 6rem;
  margin-top: 6rem;
}

.secondTitle {
  padding: 0;
  margin-bottom: 30px;
}

.description {
  padding-bottom: 1em;
}

.listHolder {
  margin-left: 3.7em;
}

.listHolder li {
  list-style: disc;
  padding-bottom: 10px;
  font-size: 1em;
  font-family: Nimbus-Sans-Light;
}

.btnStyle {
  margin: 20px 0 0 !important;
  min-width: 260px !important;
}

.boxType1 {
  margin-bottom: 30px;
}

.boxType2 {
  margin-bottom: 14px;
}

.gaainBox {
  margin-bottom: 20px;
}

.followUsBox {
  background-color: var(--gray);
  padding: 20px;
  margin-bottom: 20px;
}

.thirdTitle {
  font-size: 1.125em;
  margin-bottom: 20px;
  padding: 0;
}

.becomeProBoxBtn {
  display: block;
  margin: 20px 0 0;
  height: 36.6px;
  width: 100%;
  max-width: 100%;
}

.followUsHeader {
  border-bottom: solid 1px var(--black);
  margin-bottom: 20px;
}

.social-media ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 15px;
}

.social-media li {
  margin: 0 0.938rem 0 0;
}

.subscribeBtn {
  width: 100%;
  max-width: 388px;
  height: 42px;
}
