.container {
    box-sizing: border-box;
    padding: 8px 20px;
    background-color: var(--gray);
    margin-bottom: 30px;
}

.title {
    padding: 0;
    margin: 0;
}

.accordion {
    background-color: var(--gray);
    padding: 0;
    min-height: auto;
}

.accordionSummary {
    margin: 0;
    padding: 0;
    min-height: auto;
}

.accordionDetails {
    padding: 0;
    margin-top: 18px;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.thumbnail {
    min-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 32px;
}

.thumbnailTitleBox {
    width: 100%;
    min-width: 150px;
    height: 45px;
}

.thumbnailTitle {
    padding-bottom: 8px;
}

.sizeType {
    border: 1px solid #CECECE;
    padding: 8px 10px;
    margin-bottom: 8px;
    line-height: 1;
}

.sizeAndPriceInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.thumbnailImage {
    margin-top: 3px;
    width: 77px;
    height: auto;
    aspect-ratio: 1 / 1;
    background-color: var(--white);
    margin-right: 8px;
  }
  
  .btnStyle {
    height: 30px;
    font-family: Nimbus-Sans-Regular;
    font-size: 0.875em;
    border-radius: 3px !important;
  }