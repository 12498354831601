.loader-backdrop {
  z-index: 100000;
}

.displayNone {
  display: none !important;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
}

.loader-backdrop-indicator {
  background-color: #fff;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3f6c !important;
  border-radius: 100% !important;
}

.loader-indicator {
  width: 100%;
  height: 100%;
  min-height: 53vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader-indicator-text {
  margin-top: 1rem;
}

.loader-wrapper {
  background-color: #fff;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3f6c !important;
  border-radius: 100% !important;
}
