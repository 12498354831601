.rewardInfo {
  background-color: var(--gray);
  margin-bottom: 30px;
  padding: 20px;
}

.infoTitle {
  font-size: 0.875em;
  margin-bottom: 10px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.infoSubTitle {
  font-size: 0.75em;
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
}

.btnStyle {
  margin: 20px 0 0;
  height: 36.6px;
  min-width: 260px;
}

.gaainLogo {
  display: block;
  margin: 20px auto 10px;
  max-width: 200px;
}