.quick-view-modal {
  width: 100%;
  min-height: 24rem;
  box-sizing: content-box;
  overflow: hidden;
}

.quick-view-content {
  margin: 1rem 0;
}

.quick-view-paper {
  border-radius: 5px;
  background-color: var(--white);
  max-height: 90vh;
  overflow: auto;
}
.quick-view-close {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.quick-view-loading {
  width: 100%;
  height: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-view-title {
  color: var(--black);
}

.quick-view-ourline,
.quick-view-sizes {
  margin-top: 0.5rem;
}

.quick-view-category,
.quick-view-text {
  font-weight: 300;
}

.quick-view-sizes p {
  padding: 0;
}
.quick-view-sizes p:last-child {
  font-weight: 200;
  font-size: 0.9em;
}

.quick-view-skuType {
  text-transform: uppercase;
}
.quick-view-wrapper {
  height: 100%;
  min-height: 00px;
  cursor: pointer;
  position: relative;
}
.quick-view-img {
  width: 100%;
  height: auto;
  border: 1px solid var(--gray-400);
}
.quick-view-label {
  text-transform: uppercase;
  background-color: var(--transparent-black);
  display: inline-block;
  color: var(--white);
  padding: 0.5rem;
  position: absolute;
  font-weight: 200;
}

.sku-no-exist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.swiper {
  width: 100%;
  height: 23rem;
}

.swiper-quick-view {
  max-width: 100%;
}

.swiper-container {
  margin: 12rem;
}

.swiper-slide img {
  width: 100%;
  height: auto !important;
  padding: 1rem;
}

.quickViewPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.fabBox {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}