

.skuContainer {
    display: flex;
}

.sku {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
}

.skuType {
    padding: 0;
    font-family: Nimbus-Sans-Light;
    color: rgba(29, 30, 30, 0.80);
}

.selectedSkuType {
    font-family: Nimbus-Sans-Regular;
}

.thumbnail {
    width: 75px;
    height: 75px;
    cursor: pointer;
    background-color: var(--light-gray);
    overflow: hidden;
    margin-bottom: 5px;
}

.selectedThumbnail {
    border: 2px solid var(--black);
}

.thumbnailImg {
    width: 100%;
    height: 100%;
}

.priceTag {
    display: flex;
    align-items: baseline;
}

.productSize {
    font-size: 0.75em;
    padding: 0;
    margin-top: 5px;
}

.actionsContainer {
  display: flex;
  align-items: center;
}

.priceInput {
    margin: 0 1px;
    text-align: center;
}

.addBtn {
    margin-left: 1px;
    width: 200px;
}

.outOfStockBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}

.outOfStockText {
    text-align: center;
    position: absolute;
    bottom: -30px;
    padding: 0;
}

.discountBox {
    display: flex;
    align-items: baseline;
}

.bodyRegular {
    padding-bottom: 0;
    line-height: normal;
    margin-bottom: 10px;
}

.bodyRegularL {
    font-size: 2.125em;
    line-height: 38px;
    padding-bottom: 5px;
    font-family: Nimbus-Sans-Regular;
}

@media only screen and (max-width: 900px) {
    .addBtn {
        width: 150px;
    }
}
  
@media only screen and (max-width: 400px) {
    .addBtn {
        font-size: 12px;
    }
}